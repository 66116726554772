import { CloseCircleOutlined, DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Input, Typography } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ROOT from '../../client'
import { getAllowedFiltersForDelete } from '../../helper'
import { bulkDeleteProps, saveFilterFlag, saveQueryStack } from '../../store/actions'
import DateTimePicker from '../DateTimePicker'
import AddFilter from '../FilterPopUp/AddFilter'

import CustomButton from '../Button'
import './stylesheet.scss'

const { Text, Title } = Typography

const ModalContent = ({
	queryString,
	queryStack,
	setGraphdata,
	setQueryData,
	setSelectedOption,
	setLowerRange,
	setHigherRange,
	setFilterValue,
	setAreaQuery,
	setAnnotations,
	setGraphEvent,
	graphEvent,
	setIsLoading,
	isLoading,
	setEnableExport,
	setMinioClient,
	lowerRange,
	higherRange,
	filterValue,
	setAddFilter,
	closeDeleteModal,
	setMapping,
}) => {
	const dispatch = useDispatch()
	const [deleteInput, setDeleteInput] = useState('')
	const [clearFilter, setClearFilter] = useState(false)
	const {
		indexValue,
		indexAlias,
		aliasFlag,
		mappingImageKey,
		observedAtDetail,
		deleteRecordCount,
		searchData,
	} = useSelector((store) => store.storeProps)

	useEffect(() => {
		deleteInput !== '' && setDeleteInput('')
	}, [searchData])

	const handleDelete = () => {
		try {
			closeDeleteModal()
			setIsLoading(true)
			dispatch(saveFilterFlag({ showFilterFlag: false, isDeleteFilterFlag: false }))
			let observedAt = {
				key: 'observed_at',
				value: `${observedAtDetail?.startDate} AND ${observedAtDetail?.endDate}`,
				operator: 'BETWEEN',
				operand: 'AND',
			}
			const payload = {
				body: JSON.stringify({
					index: indexValue,
					alias: aliasFlag ? indexAlias : '',
					groups: queryString || [],
					date: observedAt,
					mappingKey: mappingImageKey,
				}),
			}
			axios
				.post(`${ROOT}/api/delete-images`, payload)
				.then((res) => {
					if (!res.data.result.uuid) throw new Error('No uuid in res')
					dispatch(
						bulkDeleteProps({
							isDeleteInProgressFlag: true,
							deleteState: 'started',
							deleteUUID: res.data.result.uuid,
						}),
					)
					setIsLoading(false)
				})
				.catch((err) => {
					console.log('Delete call failed: ', err)
					setIsLoading(false)
					dispatch(
						bulkDeleteProps({ isDeleteInProgressFlag: true, deleteState: 'error', deleteUUID: '' }),
					)
					dispatch(saveFilterFlag({ showFilterFlag: false, isDeleteFilterFlag: false }))
				})
		} catch (err) {
			console.log(err)
		}
	}

	const removeFilter = (value, index) => {
		try {
			if (queryStack.length) {
				const newStack = []
				queryStack.forEach((val, i) => {
					if (value && val.query !== value) {
						if (index - 1 === i) {
							val = {
								...val,
								allowOperand: true,
								operand:
									val.operand === 'AND'
										? 'AND'
										: `${index - 1 === queryStack.length - 2 ? 'AND' : 'OR'}`,
							}
						}
						newStack.push(val)
					}
				})
				dispatch(saveQueryStack(newStack))
				setAddFilter(false)
			}
		} catch (error) {
			console.log('Error: [DeleteQuery]: removeFilter', error)
		}
	}

	const queryFilterRenderer = (query, i) => (
		<div key={i} className='flex'>
			<div className='delete-modal-filter'>
				<span className='delete-modal-filter-key'>{query.field}</span>
				<span className='delete-modal-filter-key'> {query.operator} </span>
				<span className='delete-modal-filter-value'>{query.fieldValue}</span>
			</div>
			<CustomButton
				shape='circle'
				className='delete-modal-filter-rm-btn'
				style={{
					backgroundColor: 'white',
					color: '#4e6b8c',
				}}
				icon={<CloseCircleOutlined />}
				onClick={() => removeFilter(query.query, i)}
			/>
		</div>
	)

	return (
		<div
			style={{
				width: '600px',
				minHeight: '200px',
			}}
		>
			<div className='delete-modal-content-child-one'>
				<Title level={3}>Bulk Delete</Title>
				<Text className='space-bottom'>
					All images related to the filters below will be deleted from the database and this cannot
					be undone.
				</Text>
				<div className='warning-flex'>
					<Text>
						<b>Note: </b>
						Only{' '}
						<b>{getAllowedFiltersForDelete().splice('').join(', ').toString()} and date range </b>
						filters are used while deleting. All the rest are ignored. If any of these filters is
						not applied, all data in the selected date range will be deleted.
					</Text>
				</div>
				<div className='delete-modal-content'>
					<div style={{ alignSelf: 'center' }}>
						<DateTimePicker
							queryString={queryString}
							setGraphdata={setGraphdata}
							setQueryData={setQueryData}
							setSelectedOption={setSelectedOption}
							setLowerRange={setLowerRange}
							setHigherRange={setHigherRange}
							setFilterValue={setFilterValue}
							setAreaQuery={setAreaQuery}
							setAnnotations={setAnnotations}
							setGraphEvent={setGraphEvent}
							graphEvent={graphEvent}
							setIsLoading={setIsLoading}
							isLoading={isLoading}
							setEnableExport={setEnableExport}
							setMinioClient={setMinioClient}
							setMapping={setMapping}
							setClearFilter={() => setClearFilter(!clearFilter)}
						/>
					</div>
					<div>
						<Title level={5}>Add Filters</Title>
						<AddFilter
							lowerRange={lowerRange}
							setLowerRange={setLowerRange}
							higherRange={higherRange}
							setHigherRange={setHigherRange}
							setFilterValue={setFilterValue}
							filterValue={filterValue}
							isLoading={isLoading}
							queryStack={queryStack}
							setAddFilter={setAddFilter}
							clearFilter={clearFilter}
						/>
					</div>
					{queryStack.length > 0 ? (
						<div>
							<Text>Query Filter</Text>
							<div>{queryStack.map((query, i) => queryFilterRenderer(query, i))}</div>
						</div>
					) : (
						<div className='flex'>
							<Text>No Filters applied</Text>
						</div>
					)}
				</div>
			</div>
			<div className='delete-modal-content-child-two'>
				<div className='delete-modal-content-child-two-content'>
					{!isLoading && (
						<Text type={deleteInput === 'DELETE' && deleteRecordCount === 0 && 'danger'}>
							You&apos;ve selected <b>{deleteRecordCount}</b> records
						</Text>
					)}
					<Text>
						If you&apos;re sure, please type <b>&quot;DELETE&quot;</b> in the box
					</Text>
					<Input
						style={{ maxWidth: '70%' }}
						className='delete-modal-input'
						placeholder=''
						value={deleteInput}
						onPaste={(e) => {
							e.preventDefault()
							return false
						}}
						onCopy={(e) => {
							e.preventDefault()
							return false
						}}
						onChange={(e) => {
							setDeleteInput(e.target.value.replace(/ /g, ''))
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						gap: '10px',
						justifyContent: 'flex-end',
					}}
				>
					<CustomButton
						onClick={() => {
							dispatch(saveQueryStack([]))
						}}
					>
						No, cancel
					</CustomButton>
					<CustomButton
						onClick={handleDelete}
						className='delete-modal-btn'
						isDisabled={deleteInput !== 'DELETE' || deleteRecordCount === 0}
						type='primary'
					>
						Yes, delete
					</CustomButton>
				</div>
			</div>
		</div>
	)
}

const ModalToggleButton = ({ isOpen }) => {
	const toggleText = !isOpen ? 'Show' : 'Hide'
	const toggleIcon = !isOpen ? (
		<RightOutlined
			style={{
				fontSize: '35px',
				fontWeight: 900,
			}}
		/>
	) : (
		<LeftOutlined
			style={{
				fontSize: '35px',
				fontWeight: 900,
			}}
		/>
	)

	return (
		<div
			style={{
				height: 'inherit',
				width: 'inherit',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-end',
				alignItems: 'center',
				marginTop: '-20px',
				color: '#4E6B8C',
				fontSize: '20px',
			}}
		>
			<Text
				style={{
					writingMode: 'vertical-lr',
					letterSpacing: '1.2px',
					color: 'inherit',
					marginBottom: '10px',
				}}
			>
				{toggleText}
			</Text>
			{toggleIcon}
		</div>
	)
}

const DeleteModal = ({
	queryString,
	queryStack,
	setGraphdata,
	setQueryData,
	setSelectedOption,
	setLowerRange,
	setHigherRange,
	setFilterValue,
	setAreaQuery,
	setAnnotations,
	setGraphEvent,
	graphEvent,
	setIsLoading,
	isLoading,
	setEnableExport,
	setMinioClient,
	lowerRange,
	higherRange,
	filterValue,
	setAddFilter,
	setMapping,
}) => {
	const [isDeleteModalShow, setIsDeleteModalShow] = useState(true)
	const { isDeleteModeOn } = useSelector((store) => store.storeProps)

	return (
		<>
			{isDeleteModeOn && (
				<div
					size='large'
					type='default'
					icon={<DeleteOutlined />}
					className={'modal-delete-btn'}
					style={{
						position: 'fixed',
						right: isDeleteModalShow ? '600px' : 0,
						bottom: '0',
						transition: 'all .5s',
						zIndex: 199,
						backgroundColor: '#D9D9D9',
						borderRadius: '0 5px 5px 0',
						boxShadow: '10px 10px 20px rgba(0,0,0,0.1)',
						height: '80vh',
						cursor: 'pointer',
						transform: 'rotate(-180deg)',
						textAlign: 'end',
					}}
					onClick={() => setIsDeleteModalShow(!isDeleteModalShow)}
				>
					<ModalToggleButton isOpen={isDeleteModalShow} />
				</div>
			)}
			<div
				style={{
					transition: 'all .5s',
					position: 'fixed',
					backgroundColor: 'white',
					width: isDeleteModalShow ? '600px' : '0',
					right: '0',
					zIndex: 198,
					height: '80vh',
					bottom: '0',
					boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
					borderRadius: '2px 0 0 0px',
				}}
			>
				<div
					style={{
						opacity: isDeleteModalShow ? '1' : 0,
						transition: 'all .3s',
						whiteSpace: 'pre-line',
					}}
				>
					<ModalContent
						queryString={queryString}
						queryStack={queryStack}
						setGraphdata={setGraphdata}
						setQueryData={setQueryData}
						setSelectedOption={setSelectedOption}
						setLowerRange={setLowerRange}
						setHigherRange={setHigherRange}
						setFilterValue={setFilterValue}
						setAreaQuery={setAreaQuery}
						setAnnotations={setAnnotations}
						setGraphEvent={setGraphEvent}
						graphEvent={graphEvent}
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						setEnableExport={setEnableExport}
						setMinioClient={setMinioClient}
						lowerRange={lowerRange}
						higherRange={higherRange}
						filterValue={filterValue}
						setAddFilter={setAddFilter}
						closeDeleteModal={() => setIsDeleteModalShow(false)}
						setMapping={setMapping}
					/>
				</div>
			</div>
		</>
	)
}

ModalToggleButton.propTypes = {
	isOpen: PropTypes.bool,
}

ModalContent.propTypes = {
	queryString: PropTypes.array,
	queryStack: PropTypes.array,
	setGraphdata: PropTypes.func,
	setQueryData: PropTypes.func,
	setSelectedOption: PropTypes.func,
	setLowerRange: PropTypes.func,
	setHigherRange: PropTypes.func,
	setFilterValue: PropTypes.func,
	setAreaQuery: PropTypes.func,
	setAnnotations: PropTypes.func,
	setGraphEvent: PropTypes.func,
	graphEvent: PropTypes.string,
	setIsLoading: PropTypes.func,
	isLoading: PropTypes.bool,
	setEnableExport: PropTypes.func,
	setMinioClient: PropTypes.func,
	lowerRange: PropTypes.string,
	higherRange: PropTypes.string,
	filterValue: PropTypes.array,
	setAddFilter: PropTypes.func,
	closeDeleteModal: PropTypes.func,
	setMapping: PropTypes.func,
}

DeleteModal.propTypes = {
	queryString: PropTypes.array,
	queryStack: PropTypes.array,
	setGraphdata: PropTypes.func,
	setQueryData: PropTypes.func,
	setSelectedOption: PropTypes.func,
	setLowerRange: PropTypes.func,
	setHigherRange: PropTypes.func,
	setFilterValue: PropTypes.func,
	setAreaQuery: PropTypes.func,
	setAnnotations: PropTypes.func,
	setGraphEvent: PropTypes.func,
	graphEvent: PropTypes.string,
	setIsLoading: PropTypes.func,
	isLoading: PropTypes.bool,
	setEnableExport: PropTypes.func,
	setMinioClient: PropTypes.func,
	lowerRange: PropTypes.string,
	higherRange: PropTypes.string,
	filterValue: PropTypes.array,
	setAddFilter: PropTypes.func,
	setMapping: PropTypes.func,
}

export default DeleteModal
