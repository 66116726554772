import {
	CompletedIcon,
	FailedIcon,
	failedIconCircle,
	ForwardingPending,
	pendingStatusIcon,
	successIcon,
} from './IconSets'

export const EVENT_STATUSES = [
	{ name: 'All', value: 'all' },
	{ name: 'Completed', value: 'completed', icon: CompletedIcon, textIcon: successIcon },
	{ name: 'Failed', value: 'failed', icon: FailedIcon, textIcon: failedIconCircle },
	{
		name: 'Forward Pending',
		value: 'pending',
		icon: ForwardingPending,
		textIcon: pendingStatusIcon,
	},
]

export const LAST_POINT_OF_FAILURE = 'last_point_of_failure'
export const RAW_LOGS = 'raw_logs'
export const PEG_URL = '/peg'
export const EVENTS_CONSOLE_URL = '/events-console'
export const YET_TO_BE_FORWARDED = 'Yet to be forwarded'
export const REDACTION_VIEW = 'realTimeRedaction'
export const CAROUSEL_VIEW = 'carouselView'
