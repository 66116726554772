/* eslint-disable no-unused-vars */
import { CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import dateMath from '@elastic/datemath';
import { EuiFormControlLayoutDelimited, EuiSuperDatePicker } from '@elastic/eui';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch } from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_STATUSES } from '../../../utilities/constants';
import { generateConfig, operandList } from '../../Search/config';

const { Option } = Select;

const RegexScope = (props) => {
	const {
		setFormRowData,
		formRowData,
		setFilterGroups,
		setScopeQueryString,
		scopeQueryString,
		initialScopeData,
		setInitialScopeData,
		setCommitDisable,
	} = props;
	const [form] = Form.useForm();
	const [isScopeModalOpen, setIsScopeModelOpen] = useState(false);
	const [filterField, setFilterField] = useState([]);
	// const [filterOptions, setFilterOptions] = useState([]);
	// const [showDateText, setShowDateText] = useState(false);
	// const [showBooleanMsg, setShowBooleanMsg] = useState(false);
	// const [showFilterSelect, setShowFilterSelect] = useState(false);
	const [disabled, setDisabled] = useState(false);
	// const [nonNumeric, setNonNumeric] = useState(false);
	// const [rangeError, setRangeError] = useState(false);
	// const [dateError, setDateError] = useState(false);
	const [start, setStart] = useState('now-15m');
	const [end, setEnd] = useState('now');
	const [filterMatched, setFilterMatched] = useState(false);
	const [keyField, setKeyField] = useState('');
	const [disableValueSelector, setDisableValueSelector] = useState(false);
	const [initialRow, setInitialRow] = useState(true);
	const [duplicateFilter, setDuplicateFilter] = useState('');
	// const [date, setDate] = useState('');
	// const [removeBtnDisable, setRemoveBtnDisable] = useState(true);
	const timezone = moment.tz.guess();

	const { indexValue, indexAlias, aliasFlag, observedAtDetail, imageDetail, mappingProps } =
		useSelector((store) => store.storeProps);

	const handleScopeModalCancel = () => {
		setIsScopeModelOpen(false);
	};

	const formatDate = (start, end) => {
		// eslint-disable-next-line
		let st = moment(dateMath.parse(start)).format('YYYY-MM-DDTHH:mm:ss');
		let et = moment(
			dateMath.parse(
				end === 'now/d' || end === 'now/w' || end === 'now/M' || end === 'now/y' ? 'now' : end,
			),
		).format(end === 'now-1d/d' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss');
		if (end === 'now-1d/d') {
			et = `${et}T23:59:59`;
		}
		// eslint-disable-next-line
		const formattedStart = moment.tz(st, timezone).format();
		const formattedEnd = moment.tz(et, timezone).format();
		return `${formattedStart} AND ${formattedEnd}`;
	};

	const updateFormData = (updatedValues, key) => {
		const updatedRowData = formRowData.map((val) => {
			return val.key === key
				? {
						...val,
						...updatedValues,
				  }
				: val;
		});
		setFormRowData(updatedRowData);
	};

	const handleFormSubmit = (value) => {
		setDuplicateFilter('');
		let error = false;
		let scopeQuery = '';
		let qs = '';
		let operand = '';
		let filter = '';
		let andFilters = [];
		const filtersArr = [];
		const scope = value.scope;
		const completeScopeData = [];

		scope.forEach((d, i) => {
			const isValueMatch = scope.filter((v) => v.value == d.value);
			if (isValueMatch.length > 1) {
				setDuplicateFilter(
					`${d.field} field is duplicate. Please remove or change the duplicate values.`,
				);
				error = true;
			} else {
				let operator = d.operator == undefined ? 'AND' : d.operator ? 'AND' : 'OR';
				if (formRowData[i].fieldType === 'date') {
					scope[i].value = formRowData[i].dateValue
						? formRowData[i].dateValue
						: formatDate('now-15m', 'now');
				}
				if (formRowData[i].fieldType === 'boolean') {
					scope[i].value = scope[i].value == undefined ? true : scope[i].value;
				}

				if (
					formRowData[i]?.fieldType === 'long' &&
					(!formRowData[i]?.lowerRange || !formRowData[i]?.highRange)
				) {
					updateFormData(
						{
							rangeErrorMessage: 'Required field.',
						},
						i,
					);
					error = true;
				} else if (formRowData[i]?.fieldType === 'long') {
					scope[i].value = formRowData[i].rangeValue;
				}

				operand = operandList.find((val) => scope[i].operand == val.value);
				filter = `${scope[i].field} ${operand?.label} ${scope[i].value}`;

				completeScopeData.push({
					...formRowData[i],
					value: scope[i].value,
					operator: operator,
				});

				if (operator === 'OR') {
					qs = qs ? `${qs} AND ${filter}` : `${filter}`;
					andFilters.push({
						key: d.field,
						value: d.value,
						operator: d.operand,
						operand: operator,
					});
					filtersArr.push(andFilters);
					andFilters = [];
					scopeQuery = scopeQuery ? `${scopeQuery} OR ${qs}` : qs;
					qs = '';
				} else {
					qs = qs ? `${qs} AND ${filter}` : `${filter}`;
					andFilters.push({
						key: d.field,
						value: d.value,
						operator: d.operand,
						operand: operator,
					});
				}
			}
		});
		if (andFilters.length) {
			filtersArr.push(andFilters);
		}
		scopeQuery = scopeQuery ? `${scopeQuery} ${qs ? `OR ${qs}` : ''}` : `${qs ? `${qs}` : ''}`;
		if (error) {
			return;
		}
		setFilterGroups(filtersArr);
		setFormRowData(completeScopeData);
		setScopeQueryString(scopeQuery);

		setIsScopeModelOpen(false);
	};

	const handleFieldChange = (selectedOption, key) => {
		try {
			const currentFormValues = form.getFieldsValue();
			if (selectedOption) {
				if (currentFormValues?.scope?.length) {
					const resetFormValues = currentFormValues.scope.map((val, i) => {
						if (i == key) {
							return {
								...val,
								operand: '',
								value: '',
							};
						} else {
							return val;
						}
					});
					form.setFieldsValue({
						scope: resetFormValues,
					});
				}
				// form.resetFields([
				// 	['scope', key, 'operand'],
				// 	['scope', key, 'value'],
				// ]);
				setFilterField(selectedOption);
				setFilterMatched(false);

				let operandOpts = [
					{
						value: 'is',
						label: 'is',
					},
					{
						value: 'is not',
						label: 'is not',
					},
				];

				operandOpts = operandOpts.filter(
					(e) => e.value !== 'is between' && e.value !== 'is not between',
				);
				if (
					mappingProps[selectedOption].type === 'long' ||
					mappingProps[selectedOption].type === 'integer'
				) {
					operandOpts.push(
						{
							value: 'is between',
							label: 'is between',
						},
						{
							value: 'is not between',
							label: 'is not between',
						},
					);
				}

				if (mappingProps[selectedOption].type === 'date') {
					operandOpts = [];
					operandOpts.push({
						value: 'is between',
						label: 'is between',
					});
				}
				if (mappingProps[selectedOption].isLikeOperator) {
					operandOpts = [];
					operandOpts.push(
						{
							value: 'LIKE',
							label: 'Like',
						},
						{
							value: 'NOT LIKE',
							label: 'Not like',
						},
					);
				}
				let rowData = {
					key,
					field: selectedOption,
					fieldType: mappingProps[selectedOption].fields
						? mappingProps[selectedOption].fields.keyword.type
						: mappingProps[selectedOption].type,
					operand: '',
					operandOptions: operandOpts,
				};
				if (mappingProps[selectedOption].type === 'date') {
					rowData = { ...rowData, startDate: 'now-15m', endDate: 'now' };
				}

				if (formRowData.length) {
					const updatedData = formRowData.map((val) => {
						return val.key === key
							? {
									...rowData,
									operandOptions: operandOpts,
							  }
							: val;
					});
					setFormRowData(updatedData);

					// const isAlreadyExist = formRowData.find((val) => val.key === key);
					// if (isAlreadyExist) {
					// 	const updatedData = formRowData.map((val) => {
					// 		return val.key === key
					// 			? {
					// 					...rowData,
					// 					operandOptions: operandOpts,
					// 			  }
					// 			: val;
					// 	});
					// 	setFormRowData(updatedData);
					// } else {
					// 	setFormRowData([...formRowData, rowData]);
					// }
				}
				//  else {
				// 	setFormRowData([rowData]);
				// }
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleOperandChange = (e, key) => {
		try {
			updateFormData({ operand: e }, key);
			setFilterMatched(false);
			const fieldValue = formRowData[key]?.field || filterField;
			const fieldType = mappingProps[fieldValue].type;
			if (fieldType === 'date') {
				// setShowDateText(true);
				setDisabled(false);
				const startInit = moment(dateMath.parse(start)).format('YYYY-MM-DDTHH:mm:ss');
				const endInit = moment(dateMath.parse(end)).format('YYYY-MM-DDTHH:mm:ss');
				const st = moment.tz(startInit, timezone).format();
				const et = moment.tz(endInit, timezone).format();
				// setFilterValue(`${st} AND ${et}`);
			} else {
				// setShowDateText(false);
			}
			if (fieldType === 'boolean') {
				// setFilterValue('false');
				// setShowBooleanMsg(true);
				setDisabled(false);
			} else {
				// setShowBooleanMsg(false);
			}
			if (
				fieldType !== 'date' &&
				fieldType !== 'long' &&
				fieldType !== 'integer' &&
				fieldType !== 'boolean'
			) {
				// if (keyField !== filterField || isDeleteModeOn) {
				// setFilterOptions([]);
				setDisableValueSelector(true);
				const valueDetail = imageDetail.filter((val) => val?.value === fieldValue);
				generateConfig(
					indexValue,
					indexAlias,
					aliasFlag,
					valueDetail,
					observedAtDetail.startDate,
					observedAtDetail.endDate,
				)
					.then((res) => {
						if (res?.fields) {
							setKeyField(fieldValue);
							const fieldDetail = res.fields;
							const filteredOptions = fieldDetail[fieldValue]?.options
								? fieldDetail[fieldValue].options
								: [];

							if (fieldValue === 'forward_status') {
								const updatedFields = [];
								filteredOptions.map((el) => {
									EVENT_STATUSES.map(
										(a) =>
											a?.value === el?.value &&
											updatedFields.push({ label: a?.name, value: a?.value }),
									);
								});
								updateFormData({ values: updatedFields, operand: e }, key);

								// setFilterOptions(updatedFields);
							} else {
								updateFormData({ values: filteredOptions, operand: e }, key);
								// setFilterOptions(filteredOptions);
							}
							setDisableValueSelector(false);
						}
					})
					.catch((error) => {
						setDisableValueSelector(false);
						console.log('error: ', error);
					});
				// }
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleRemoveRow = (key) => {
		const newRowArray = [...formRowData];
		newRowArray.splice(key, 1);
		const updatedRowKeys = newRowArray.map((val, i) => {
			return {
				...val,
				key: i,
			};
		});
		setFormRowData(updatedRowKeys);
	};

	const onTimeChange = (e, key) => {
		try {
			let dateErrorStatus = false;
			setDisabled(false);
			setFilterMatched(false);
			if (e.isInvalid) {
				setDisabled(true);
				dateErrorStatus = true;
			}
			setStart(e.start);
			setEnd(e.end);
			updateFormData(
				{
					dateErrorStatus,
					startDate: e.start,
					endDate: e.end,
					dateValue: formatDate(e.start, e.end),
				},
				key,
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleLowerRange = (e, key) => {
		const lowerValue = Number(e?.target?.value);
		const highValue = Number(formRowData[key]?.highRange);
		let isValueGreater = highValue <= lowerValue ? true : false;
		updateFormData(
			{
				lowerRange: e?.target?.value,
				rangeErrorMessage: isValueGreater ? 'End range should be greater than start range.' : '',
				rangeValue: !isValueGreater ? `${lowerValue} AND ${highValue}` : '',
			},
			key,
		);
		setDisabled(isValueGreater);
	};

	const handleHighRange = (e, key) => {
		const lowerValue = Number(formRowData[key]?.lowerRange);
		const highValue = Number(e?.target?.value);
		let isValueGreater = lowerValue >= highValue ? true : false;
		updateFormData(
			{
				highRange: e?.target?.value,
				rangeErrorMessage: isValueGreater ? 'End range should be greater than start range.' : '',
				rangeValue: !isValueGreater ? `${lowerValue} AND ${highValue}` : '',
			},
			key,
		);
		setDisabled(isValueGreater);
	};

	const handleFormValueChange = () => {
		setDuplicateFilter('');
	};

	const addNewRow = () => {
		let rowData = {
			key: formRowData[formRowData.length - 1]?.key + 1,
			field: '',
			fieldType: '',
			operand: '',
			operandOptions: [],
		};
		setFormRowData([...formRowData, rowData]);
	};

	const handleClearScope = () => {
		setCommitDisable(true);
		setInitialScopeData([]);
		setFormRowData([
			{
				key: 0,
				field: '',
				fieldType: '',
				operand: '',
				operandOptions: [],
			},
		]);
		setFilterGroups([]);
		setScopeQueryString('');
		setInitialRow(true);
		handleScopeModalCancel();
	};

	useEffect(() => {
		if (formRowData?.length && formRowData[0]?.field) {
			form.resetFields();
			const updatedFormValues = formRowData.map((val) => {
				return {
					field: val.field,
					operand: val.operand,
					value: val.value,
					operator: val.operator == 'AND' ? true : false,
				};
			});
			setInitialScopeData(updatedFormValues);
		}
	}, [isScopeModalOpen]);

	return (
		<div className='regexScope'>
			{scopeQueryString ? (
				<>
					<Button onClick={() => setIsScopeModelOpen(true)} className='bat-sec'>
						Edit Scope
					</Button>
					<div className='scopeQueryString'>
						Scope applied:
						<div className='queryBlock'>
							{scopeQueryString} <CloseOutlined onClick={handleClearScope} />
						</div>
					</div>
				</>
			) : (
				<Button onClick={() => setIsScopeModelOpen(true)} className='bat-sec'>
					Add Scope
				</Button>
			)}

			<Modal
				className='regexScopeModal'
				title={
					<>
						<h3>Add Scope</h3>
						<p>Decide which images to apply regex/words to</p>
					</>
				}
				visible={isScopeModalOpen}
				onCancel={handleScopeModalCancel}
				footer={[]}
				width={850}
				maskClosable={false}
			>
				<Form
					onFinish={handleFormSubmit}
					form={form}
					layout='vertical'
					requiredMark={false}
					initialValues={initialScopeData.length ? { scope: initialScopeData } : { scope: [] }}
					onValuesChange={handleFormValueChange}
					key={scopeQueryString}
				>
					<Form.List name='scope'>
						{(fields, { add, remove }) => {
							if (initialRow && initialScopeData.length == 0) {
								form.resetFields();
								add();
								setInitialRow(false);
							}
							return (
								<>
									{fields.map(({ key, name, ...restField }) => {
										return (
											<div key={key} className='fieldsRow'>
												<Row gutter={20} align='middle'>
													<Col span={8}>
														<Form.Item
															{...restField}
															label='Field'
															name={[name, 'field']}
															rules={[
																{
																	required: true,
																	message: 'Required field.',
																},
															]}
														>
															<Select showSearch onChange={(e) => handleFieldChange(e, name)}>
																{imageDetail.length > 0 &&
																	imageDetail.map((element, index) => {
																		if (
																			(element.label == 'observed_at' ||
																				element.label == 'application' ||
																				element.label == 'user' ||
																				element.label == 'machine' ||
																				element.label == 'title' ||
																				element.label == 'session_uuid') &&
																			element.type !== 'nested'
																		) {
																			return (
																				<Option key={index} value={element.label}>
																					{element.label}
																				</Option>
																			);
																		} else {
																			return null;
																		}
																	})}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restField}
															label='Operand'
															name={[name, 'operand']}
															rules={[
																{
																	required: true,
																	message: 'Required field.',
																},
															]}
														>
															<Select onChange={(e) => handleOperandChange(e, name)}>
																{formRowData.length > 0 &&
																	formRowData[name] &&
																	formRowData[name].operandOptions.map((element, index) => {
																		return (
																			<Option key={index} value={element.label}>
																				{element.label}
																			</Option>
																		);
																	})}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
														{formRowData.length > 0 ? (
															<>
																{formRowData[name]?.operand === 'is' ||
																formRowData[name]?.operand === 'is not' ||
																formRowData[name]?.operand === 'Like' ||
																formRowData[name]?.operand === 'Not like' ? (
																	<>
																		{formRowData[name]?.fieldType === 'keyword' ? (
																			<Form.Item
																				{...restField}
																				label='Value'
																				name={[name, 'value']}
																				rules={[
																					{
																						required: true,
																						message: 'Required field.',
																					},
																				]}
																			>
																				<Select
																					showSearch
																					placeholder='Select a value'
																					optionFilterProp='children'
																					id='filtervalselect'
																					disabled={disableValueSelector}
																					loading={disableValueSelector}
																					// onChange={onChangeFilterVal}
																					// onSearch={onSearchChange}
																					filterOption={(input, option) =>
																						option.children
																							.toLowerCase()
																							.indexOf(input.toLowerCase()) >= 0
																					}
																				>
																					{formRowData[name]?.values?.length > 0 &&
																						formRowData[name]?.values.map((element, index) => {
																							return (
																								<Option key={index} value={element.value}>
																									{element.label}
																								</Option>
																							);
																						})}
																				</Select>
																			</Form.Item>
																		) : formRowData[name]?.fieldType === 'boolean' ? (
																			<Form.Item
																				{...restField}
																				label='Value'
																				name={[name, 'value']}
																			>
																				<Switch
																					defaultChecked={
																						initialScopeData[name]?.value == undefined
																							? true
																							: initialScopeData[name]?.value
																					}
																					checkedChildren='True'
																					id='booleanField'
																					unCheckedChildren='False'
																					// onClick={onBooleanChange}
																					style={{ backgroundColor: '#4e6b8c' }}
																				/>
																			</Form.Item>
																		) : (
																			<Form.Item
																				{...restField}
																				label='Value'
																				name={[name, 'value']}
																				rules={[
																					{
																						required: true,
																						message: 'Required field.',
																					},
																				]}
																			>
																				<Input
																					placeholder='Enter a value'
																					// onChange={(e) => onChangeFilterVal(e, 'input')}
																					maxLength={18}
																				/>
																			</Form.Item>
																		)}
																	</>
																) : (
																	<>
																		{formRowData[name]?.operand === 'is between' ||
																		formRowData[name]?.operand === 'is not between' ? (
																			<>
																				{formRowData[name]?.fieldType === 'date' ? (
																					<>
																						<Form.Item
																							{...restField}
																							label='Value'
																							name={[name, 'value']}
																						>
																							<EuiSuperDatePicker
																								id='datePicker'
																								showUpdateButton={false}
																								onTimeChange={(e) => onTimeChange(e, name)}
																								start={formRowData[name]?.startDate}
																								end={formRowData[name]?.endDate}
																							/>
																						</Form.Item>
																						{formRowData[name]?.dateErrorStatus && (
																							<div className='color-red'>
																								End date should be higher than start date
																							</div>
																						)}
																					</>
																				) : (
																					<>
																						<Form.Item
																							{...restField}
																							label='Value'
																							name={[name, 'value']}
																						>
																							<EuiFormControlLayoutDelimited
																								className={
																									formRowData[name]?.rangeErrorMessage
																										? 'rangeError'
																										: ''
																								}
																								startControl={
																									<input
																										type='text'
																										placeholder='Start of range'
																										className='euiFieldNumber'
																										onChange={(e) => handleLowerRange(e, name)}
																										// value={lowerRange}
																										id='lowerrange'
																										aria-label='Use aria labels when no actual label is in use'
																										maxLength={18}
																										value={formRowData[name]?.lowerRange}
																									/>
																								}
																								endControl={
																									<input
																										type='text'
																										placeholder='End of range'
																										className='euiFieldNumber'
																										id='higherrange'
																										onChange={(e) => handleHighRange(e, name)}
																										// value={higherRange}
																										aria-label='Use aria labels when no actual label is in use'
																										maxLength={18}
																										value={formRowData[name]?.highRange}
																									/>
																								}
																							/>
																						</Form.Item>
																						{formRowData[name]?.rangeErrorMessage && (
																							<div className='color-red'>
																								{formRowData[name]?.rangeErrorMessage}
																							</div>
																						)}
																					</>
																				)}
																			</>
																		) : (
																			<Form.Item
																				{...restField}
																				label='Value'
																				name={[name, 'value']}
																				rules={[
																					{
																						required: true,
																						message: 'Required field.',
																					},
																				]}
																			>
																				<Input
																					placeholder='Enter a value'
																					// onChange={(e) => onChangeFilterVal(e, 'input')}
																					maxLength={18}
																				/>
																			</Form.Item>
																		)}
																	</>
																)}
															</>
														) : (
															<Form.Item
																{...restField}
																label='Value'
																name={[name, 'value']}
																rules={[
																	{
																		required: true,
																		message: 'Required field.',
																	},
																]}
															>
																<Input
																	placeholder='Enter a value'
																	// onChange={(e) => onChangeFilterVal(e, 'input')}
																	maxLength={18}
																/>
															</Form.Item>
														)}
													</Col>
												</Row>
												{fields.length - 1 != name && (
													<Form.Item
														className='operator'
														name={[name, 'operator']}
														valuePropName='checked'
													>
														<Switch
															defaultChecked={
																initialScopeData[name]?.operator
																	? initialScopeData[name]?.operator
																	: true
															}
															unCheckedChildren='OR'
															checkedChildren='AND'
														></Switch>
													</Form.Item>
												)}
												{restField.fieldKey > 0 && (
													<MinusCircleOutlined
														onClick={() => {
															remove(name);
															handleRemoveRow(name);
														}}
													/>
												)}
											</div>
										);
									})}
									<Form.Item className='addScopeBtn'>
										<Button
											className='bat-sec'
											onClick={() => {
												add();
												addNewRow();
											}}
										>
											Add another scope
										</Button>
									</Form.Item>
								</>
							);
						}}
					</Form.List>
					{duplicateFilter && <p className='duplicateFilter'>{duplicateFilter}</p>}
					<Form.Item className='scopeFooter'>
						<Space>
							<Button className='bat-primary' type='primary' htmlType='submit' disabled={disabled}>
								Save
							</Button>
							<Button className='bat-sec' onClick={handleScopeModalCancel}>
								Cancel
							</Button>
							{/* <Button
								disabled={initialScopeData.length ? false : removeBtnDisable}
								className='bat-sec'
								onClick={handleClearScope}
							>
								Remove Scope
							</Button> */}
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

RegexScope.propTypes = {
	setPreviewDisable: PropTypes.func,
	previewDisable: PropTypes.bool,
	setFilterGroups: PropTypes.func,
	formRowData: PropTypes.array,
	setFormRowData: PropTypes.func,
	scopeQueryString: PropTypes.string,
	setScopeQueryString: PropTypes.func,
	initialScopeData: PropTypes.array,
	setInitialScopeData: PropTypes.func,
	setCommitDisable: PropTypes.func,
};

export default RegexScope;
